import { Environment } from "./app.interface";

/**
 * 현재 실행중인 환경
 */
export const runtimeEnv = process.env.NEXT_PUBLIC_DEPLOY_ENVIRONMENT || "development";

/**
 * 현재 실행중인 환경
 */
export const env: Environment = ((rEnv: string | undefined): Environment => {
  switch (rEnv) {
    case "production": {
      return Environment.PRODUCTION;
    }
    case "staging": {
      return Environment.STAGING;
    }
    case "development":
    default: {
      return Environment.DEVELOPMENT;
    }
  }
})(runtimeEnv);

/**
 * 개발환경 여부
 * → 스테이징 또는 개발환경인 경우 `true` 반환
 */
export const isDevelopment = env !== Environment.PRODUCTION;

/**
 * CDN 경로
 * → 웹 사이트 캡쳐 이미지등에 사용
 */
export const cdnUrl = process.env.NEXT_PUBLIC_CDN_URL;

/**
 * Sentry DSN 정보
 */
export const sentryDsn = process.env.NEXT_PUBLIC_SENTRY_DSN;

/**
 * 로컬 개발 모드 환경
 */
export const isLocalDev = process.env.NODE_ENV === "development";

/**
 * 스토리북 실행중 여부
 */
export const isStorybookRuntime = process.env.STORYBOOK === "true";
