var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"uBz1jKS4PR1KZ5gqjtKur"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { SamplingContext } from "@sentry/types";
import * as Sentry from "@sentry/nextjs";
import {
  sentryDsn as dsn,
  runtimeEnv as environment,
} from "./src/app.constant";

if (dsn) {
  Sentry.init({
    dsn,
    environment,
    /**
     * 샘플링 데이터 필터 설정
     * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/#setting-a-sampling-function
     */
    tracesSampler: (context: SamplingContext) => {
      const ignorePaths = ["/health", "/_error"];
      if (ignorePaths.includes(context.transactionContext.name)) {
        /**
         * 해당 경로로 접근시 샘플링 대상에서 제외
         */
        return 0;
      }
      return 0.001;
    },
  });
}
