/**
 * 실행 환경
 */
export enum Environment {
  /**
   * 개발
   */
  DEVELOPMENT = "dev",
  /**
   * 스테이징
   */
  STAGING = "stg",
  /**
   * 상용
   */
  PRODUCTION = "prd",
}

/**
 * 데이터 처리 상태
 */
export enum DataProcessingStatus {
  /**
   * 대기
   */
  IDLE = "IDLE",
  /**
   * 조회중
   */
  PENDING = "PENDING",
  /**
   * 처리 완료
   */
  FULFILLED = "FULFILLED",
  /**
   * 처리 실패
   */
  REJECTED = "REJECTED",
}
